import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Rest Day.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Judging for workout 17.1 of the CrossFit Open will continue today
during class for unlimited members. If you are an open gym member please
contact Daniel to set up a time to be judged.  Today is the last day to
submit your scores so don’t forget!! `}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      